<template>
  <div>
    <el-dialog v-model="dialogVisible" title="设置区域" width="50%" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">
      <div class="firmbox">
        <!-- <div><span style="color:red">*</span>地图区域：</div> -->
        <ls-address @adr-change="getAddress" :num="3" style="display: flex;justify-content: space-between;width:100%;"></ls-address>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel" type="primary" plain>取消</el-button>
          <el-button type="primary" @click="sure">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>

import {
  setDistrictArea,
} from '../../../api/api'
import until from '../../../until/until'
import session from '../../../store/store'

export default {
  data() {
    return {
      address: {
        province:'',
        city:'',
        district:'',
      },
      role:0,
      mapData:{
        name:'中国',
        level:0,
        adcode:'100000',
      },
     
      dialogVisible: true,

      province:{
        name:'',
        level:1,
        adcode:'',
      },
      city:{
        name:'',
        level:2,
        adcode:'',
      },
      district:{
        name:'',
        level:3,
        adcode:'',
      }
      
    }
  },
  mounted(){
    this.init();
  },
  methods: {
    init() {
      this.role = session.getLoginInfo().accountType;
    },
    //地址改变事件
    getAddress(data) {
      if (!until.isNULL(data.selectProvince)) {
        this.address.province = data.selectProvince;
      }else {
        this.address.province = '';
      }

      if(data.level == 1) {
        this.province.name = data.selectProvince;
        this.province.adcode = data.adcode;
        this.province.level = 1
      }


      if (!until.isNULL(data.selectCity)) {
        this.address.city = data.selectCity;
      }else {
        this.address.city = '';
      }
      if(data.level == 2) {
        this.city.name = data.selectCity;
        this.city.adcode = data.adcode;
        this.city.level = 2
      }

      if (!until.isNULL(data.selectDistrict)) {
        this.address.district =  data.selectDistrict;
      }else {
        this.address.district =  '';
      }

      if(data.level == 3) {
        this.district.name = data.selectDistrict;
        this.district.adcode = data.adcode;
        this.district.level = 3
      }

    },

    cancel() {
      this.$emit("cancel")
    },
    close() {
      this.$emit("update",this.mapData)
    },

    sure() {
      if (!until.isNULL(this.address.province)
      &&!until.isNULL(this.address.city)
      &&!until.isNULL(this.address.district)
      ) {
        this.mapData.name = this.address.province + '#' + this.address.city + '#' + this.address.district
        this.mapData.level = 3
        this.mapData.adcode = this.district.adcode
      }

      if (!until.isNULL(this.address.province)
      &&!until.isNULL(this.address.city)
      &&until.isNULL(this.address.district)
      ) {
        this.mapData.name = this.address.province + '#' + this.address.city
        this.mapData.level = 2
        this.mapData.adcode = this.city.adcode
      }

      if (!until.isNULL(this.address.province)
      &&until.isNULL(this.address.city)
      &&until.isNULL(this.address.district)
      ) {
        this.mapData.name = this.address.province
        this.mapData.level = 1
        this.mapData.adcode = this.province.adcode
      }

      if (until.isNULL(this.address.province)
      &&until.isNULL(this.address.city)
      &&until.isNULL(this.address.district)
      ) {
        this.mapData.name = '中国'
        this.mapData.level = 0
        this.mapData.adcode = '100000'
      }



      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        role:this.role,
        name:this.mapData.name,
        level:this.mapData.level,
        adcode:this.mapData.adcode
      }
      setDistrictArea(param).then(res => {
        if (res.data.code == 200) {
          this.cancel();
          this.close();
        }else {
          this.$message.error({ message: res.data.message});
        }
      })
      

      
    }
  }

}
</script>

<style lang="scss" scoped>
.firmbox {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  div {
    width: 100px;
  }
}
</style>