/**
 * 设备类型
 */

// var _ageData = [0, 0, 0, 0, 0]
// var _maleData = [0, 0, 0, 0, 0]
// var _femaleData = [0, 0, 0, 0, 0]
export default {
    setOption(echarts, option) {
        const doc = document.getElementById("sexType")
        let myChart = echarts.getInstanceByDom(doc);
        if (myChart == undefined) {
            myChart = echarts.init(doc); // 基于准备好的dom，初始化echarts实例
        }
        myChart.clear(); // 清除画布
        doc.setAttribute('_echarts_instance_', '')
        myChart.setOption(option); // 使用刚指定的配置项和数据显示图表。
    },

    setAgeSexChart(echarts,ageData,maleData,femaleData) {
        var option = {
            tooltip: {
              // backgroundColor: "rgba(255,255,255,0.8)",
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
              textStyle: {
                // color: "#fff",
              },
              formatter: function (params) {
                var res = // 字符串形式的html标签会被echarts转换渲染成数据，这个res主要是画的tooltip里的上部分的标题部分
                "<div style='margin-bottom:5px;padding:0 12px;width:100%;height:24px;line-height:24px;border-radius:3px;'><p>" +
                params[0].name +
                " </p></div>";
                // var age = 0
                // for (var i = 0; i < params.length; i++) {
                //   //因为是个数组，所以要遍历拿到里面的数据，并加入到tooltip的数据内容部分里面去
                //   var data = params[i].data;
                //   if(0 == i) {
                //     age = params[i].data;
                //   }else {
                //     if(0 != age) {
                //       data = Math.round((params[i].data / age) * 100) + '%'
                //     }else {
                //       data = 0 + '%'
                //     }
                //   }
                //   res += `<div style="font-size: 14px; padding:0 12px;line-height: 24px">
                //       <span style="display:inline-block;margin-right:5px;border-radius:2px;width:10px;height:10px;background-color:${[
                //         params[i].color, // 默认是小圆点，我们将其修改成有圆角的正方形，这里用的是模板字符串。并拿到对应颜色、名字、数据
                //       ]};"></span>
                //       ${params[i].seriesName}
                //       ${data}
                //     </div>`;
                // }


                var age = 0
                var male = 0
                var female = 0
                var maleColor
                var feamleColor
                for (var i = 0; i < params.length; i++) {
                  //因为是个数组，所以要遍历拿到里面的数据，并加入到tooltip的数据内容部分里面去
                  // var data = params[i].data;
                  if(0 == i) {
                    male = params[i].data;
                    maleColor = params[i].color
                  }else if(1 == i) {
                    female = params[i].data;
                    feamleColor = params[i].color
                  }
                }
                age = male + female
                res += `<div style="font-size: 14px; padding:0 12px;line-height: 24px">
                      <span style="display:inline-block;margin-right:5px;border-radius:2px;width:10px;height:10px;background-color:${[
                        maleColor, // 默认是小圆点，我们将其修改成有圆角的正方形，这里用的是模板字符串。并拿到对应颜色、名字、数据
                      ]};"></span>
                      总数
                      ${age}
                    </div>`;

                var showData = 0
                if(0 != age) {
                  showData = male +  ' (' + Math.round((male / age) * 100) + '%' + ')'
                }else {
                  showData = 0 + '%'
                }

                res += `<div style="font-size: 14px; padding:0 12px;line-height: 24px">
                      <span style="display:inline-block;margin-right:5px;border-radius:2px;width:10px;height:10px;background-color:${[
                        maleColor, // 默认是小圆点，我们将其修改成有圆角的正方形，这里用的是模板字符串。并拿到对应颜色、名字、数据
                      ]};"></span>
                      男
                      ${showData}
                    </div>`;

                if(0 != age) {
                  showData = female +  ' (' +Math.round((female / age) * 100) + '%' + ')'
                }else {
                  showData = 0 + '%'
                }
    
                res += `<div style="font-size: 14px; padding:0 12px;line-height: 24px">
                      <span style="display:inline-block;margin-right:5px;border-radius:2px;width:10px;height:10px;background-color:${[
                        feamleColor, // 默认是小圆点，我们将其修改成有圆角的正方形，这里用的是模板字符串。并拿到对应颜色、名字、数据
                      ]};"></span>
                      女
                      ${showData}
                    </div>`;
              
                return res; // 经过这么一加工，最终返回出去并渲染，最终就出现了我们所看的效果
              }
            },
            grid: {
              left: '1%',
              right: '1%',
              top: '12%',
              bottom: '1',
              containLabel: true,
            },
            xAxis: [
              {
                type: 'category',
                data: ['0-6(童年)','7-17(少年)','18-40(青年)','41-65(中年)','66以上(老年)'],
                axisTick: {
                  alignWithLabel: true
                },
                axisLine: {
                    lineStyle: {           
                        // color: '#fff'
                    }
                  }
              }
            ],
            yAxis: [
              {
                type: 'value',
                splitLine:{
                  show: false
                },
                axisLine: {
                    lineStyle: {           
                        // color: '#fff'
                    }
                  }
              }
            ],
            series: [
              // {
              //   name: '总数',
              //   type: 'bar',
              //   emphasis: {
              //     focus: 'series'
              //   },
              //   data: ageData
              // },
              {
                name: '男',
                type: 'bar',
                stack: 'Ad',
                emphasis: {
                  focus: 'series'
                },
                data: maleData
              },
              {
                name: '女',
                type: 'bar',
                stack: 'Ad',
                emphasis: {
                  focus: 'series'
                },
                data: femaleData
              },
            ]
          };

        this.setOption(echarts, option);
    },

    // setValue(ageData,maleData,femaleData) {
    //   _ageData = ageData
    //   _maleData = maleData
    //   _femaleData = femaleData
    // }
};