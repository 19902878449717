<!--首页-->
<template>
  <section>
    <div class="page">
      <ls-title title="综合报表"></ls-title>

      <!--头部数量统计-->
      <div class="box">
        
        <div class="topBox topBoxBg">
          <div class="topTextColor topText topTextSize1">
            <span style="align-self:center">用户总数</span>
          </div>
          <div class="topTextColor topText topTextSize2" >
            <span style="align-self:center">{{this.user.total}}</span>
          </div>

          <div class="topTextColor topText topTextSize3" >
            <div style="align-self:center;width: 100%;">
                <span >新用户</span>
                <div style="float:right;width:70%;">
                  <span style="float:right;margin-right: 20px;margin-top: 2%;">{{this.user.newNum}}</span>
                  <span style="float:right;">今日新增:</span>
                </div>
            </div>
          </div>
        </div>

        <div class="topBox topBoxBg1">
          <div class="topTextColor topText topTextSize1">
            <span style="align-self:center">设备总数</span>
          </div>
          <div class="topTextColor topText topTextSize2" >
            <span style="align-self:center">{{this.device.total}}</span>
          </div>

          <div class="topTextColor topText topTextSize3" >
            <div style="align-self:center;width: 100%;">
                <span >新设备</span>
                <div style="float:right;width:70%;">
                  <span style="float:right;margin-right: 20px;margin-top: 2%;">{{this.device.newNum}}</span>
                  <span style="float:right;">今日新增:</span>
                </div>
            </div>
          </div>
        </div>

        <div class="topBox topBoxBg2">
          <div class="topTextColor topText topTextSize1">
            <span style="align-self:center">健康数据总数</span>
          </div>
          <div class="topTextColor topText topTextSize2" >
            <span style="align-self:center">{{this.health.total}}</span>
          </div>

          <div class="topTextColor topText topTextSize3" >
            <div style="align-self:center;width: 100%;">
                <span>健康数据</span>
                <div style="float:right;width:70%;">
                  <span style="float:right;margin-right: 20px;margin-top: 2%;">{{this.health.newNum}}</span>
                  <span style="float:right;">今日新增:</span>
                </div>
            </div>
          </div>
        </div>

        <div class="topBox topBoxBg3">
          <div class="topTextColor topText topTextSize1">
            <span style="align-self:center">预警总数</span>
          </div>
          <div class="topTextColor topText topTextSize2" >
            <span style="align-self:center">{{this.warning.total}}</span>
          </div>

          <div class="topTextColor topText topTextSize3" >
            <div style="align-self:center;width: 100%;">
                <span >预警数量</span>
                <div style="float:right;width:70%;">
                  <span style="float:right;margin-right: 20px;margin-top: 2%;">{{this.warning.newNum}}</span>
                  <span style="float:right;">今日新增:</span>
                </div>
            </div>
          </div>
        </div>

      </div>

      <!--中间图形统计-->
      <div class="box" style="margin-top: 20px;">
        <div class="middleBox">

          <div class="title">
            <span class="title_ch">设备类型</span>
            <!-- <span class="title_en">Equipment type</span> -->
          </div>

          <div style="display: flex; align-items: center">
            <div id="devType" class="pieStyle"></div>
            <!-- <div class="pieTextBox">
              <div class="pieText">
                <span class="dot" style="background: #35EFE0;"></span>
                <span class="pieTitle">门磁感应器</span><span></span>
              </div>
              <div class="pieText pieTextMargin">
                <span class="dot" style="background: #0075FD;"></span>
                <span class="pieTitle">人体感应器</span><span></span>
              </div>
              <div class="pieText pieTextMargin">
                <span class="dot" style="background: #9383F6;"></span>
                <span class="pieTitle">烟雾感应器</span><span></span>
              </div>
              <div class="pieText pieTextMargin">
                <span class="dot" style="background: #FF9452;"></span>
                <span class="pieTitle">燃气感应器</span><span></span>
              </div>
              <div class="pieText pieTextMargin">
                <span class="dot" style="background: #FA7F97;"></span>
                <span class="pieTitle">其它&emsp;&emsp;&emsp;</span><span></span>
              </div>
            </div> -->
          </div>

        </div>

        <div class="middleBox">
          <div class="title">
            <span class="title_ch">预警类型</span>
            <!-- <span class="title_en">Early warning type</span> -->
          </div>

          <div style="display: flex; align-items: center">
            <div id="waringType" class="pieStyle"></div>
            <!-- <div class="pieTextBox">
              <div class="pieText">
                <span class="dot" style="background: #35EFE0;"></span>
                <span class="pieTitle">门磁报警</span><span></span>
              </div>
              <div class="pieText pieTextMargin">
                <span class="dot" style="background: #0075FD;"></span>
                <span class="pieTitle">人体报警</span><span></span>
              </div>
              <div class="pieText pieTextMargin">
                <span class="dot" style="background: #9383F6;"></span>
                <span class="pieTitle">烟雾报警</span><span></span>
              </div>
              <div class="pieText pieTextMargin">
                <span class="dot" style="background: #FF9452;"></span>
                <span class="pieTitle">燃气报警</span><span></span>
              </div>
              <div class="pieText pieTextMargin">
                <span class="dot" style="background: #FA7F97;"></span>
                <span class="pieTitle">其它&emsp;&emsp;</span><span></span>
              </div>
            </div> -->
          </div>
        </div>

        <div class="middleTailBox">

          <div class="updownBox" style="height:48%;">
            <div class="title">
              <span class="title_ch">设备状态</span>
            </div>

          <div class="device-type-half-box">
            <div class="warning-type-half-number-box">
              <div style="align-self: center;width:100%;">
                <div style="font-size: 28px;color: #2C61EF;text-align: center;font-weight:bold;">
                  <span>{{this.deviceState.onLine }}</span>
                </div>
                <div style="width:100%;font-size: 13px;text-align: center;margin-top: 10px;">
                  <span>在线数量</span> 
                </div>
              </div>
            </div>
          </div>
          <div class="device-type-half-box">
            <div class="warning-type-half-number-box">
              <div style="align-self:center;width:100%;">
                <div style="font-size: 28px;color: #FFBB32;text-align: center;font-weight:bold;">
                  <span>{{this.deviceState.offLine }}</span>
                </div>
                <div style="width:100%;font-size: 13px;text-align: center;margin-top: 10px;">
                  <span>离线数量</span> 
                </div>
              </div>
            </div>
          </div>

          </div>


          <div class="updownBox" style="margin-top: 10px;height:49%;">
              <div class="title">
                <span class="title_ch">预警状态</span>
              </div>

            <div class="device-type-half-box">
              <div class="warning-type-half-number-box">
                <div style="align-self:center;width:100%;">
                  <div style="font-size: 28px;color: #2C61EF;text-align: center;font-weight:bold;">
                    <span>{{ this.warningState.processed }}</span>
                  </div>
                  <div style="width:100%;font-size: 13px;text-align: center;margin-top: 10px;">
                    <span>已处理</span> 
                  </div>
                </div>
              </div>
            </div>
            <div class="device-type-half-box">
              <div class="warning-type-half-number-box">
                <div style="align-self:center;width:100%;">
                  <div style="font-size: 28px;color: #FFBB32;text-align: center;font-weight:bold;">
                    <span>{{ this.warningState.notProcessed }}</span>
                  </div>
                  <div style="width:100%;font-size: 13px;text-align: center;margin-top: 10px;">
                    <span>未处理</span> 
                  </div>
                </div>
              </div>
            </div>
      
          </div>

        </div>
      </div>


      <div class="box-bottom" style="margin-top: 20px;">

        <div class="left-box">

          <div style="width:100%;height: 100%;">

              <div class="title">
                <span class="title_ch">年龄/性别</span>
              </div>

              <div id="sexType" class="columnar-style"></div>
          </div>

        </div>
        <div class="right-box">
          <div style="width:100%;height: 100%;">
            <div class="title">
              <span class="title_ch">{{cityName}}【总数:{{userNumber}}】</span>

              <div>
                <!-- <el-button type="primary" size="small" style="float:right;margin-left: 5px;" @click="setMapArea">设置</el-button> -->
                <el-image style="float:right;margin-left: 5px; width: 28px; height: 28px;cursor: pointer;" @click="setMapArea" :src="require('../../../assets/img/district-set.png')"></el-image>
                <div style="float:right;">
                  <el-select v-model="mapType" @change="changeMapType($event)" placeholder="分布图" size="small" style="width: 120px;">
                    <el-option v-for="item in mapOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </div>
                
              </div>
              
            </div>

            <div id="mapType" class="map-style"></div>
          </div>
          
        </div>
      </div>

      <template v-if="mapAreaShow">
        <MapArea @update="update" @cancel="(mapAreaShow=false)"></MapArea>
      </template>
    </div>
  </section>
</template>

<script src='./js/iufo.js'></script>

<style scoped src="./css/iufo.css"></style>
