
import { 
    getDistrict,
    getDistrictUserCountInfos,
    getDistrictDeviceCountInfos
} from '../../../../api/api'
import session from "../../../../store/store";
var _map;
var _mapData = []
var _myChart = null;
var _mapJson = null;
var _option = null;
var _cityName = '中国';
var _echarts = null;
var _maxValue = 0
var _minValue = 0

export default {
    initMap(echarts,cityName,adcode,level,mapType,userNumberFunc) {
        _echarts = echarts;
        this.initEcharts(echarts);
        _cityName = cityName;
        // this.getDistrict(_cityName, '511800');
        if(mapType == 1) {
            this.getDistrictUserCountInfos(_cityName, adcode,level,userNumberFunc)
        }else if(mapType == 2) {
            this.getDistrictDeviceCountInfos(_cityName, adcode,level,userNumberFunc)
        }
        
    },
    initEcharts(echarts) {
        const doc = document.getElementById("mapType")
        _myChart = echarts.getInstanceByDom(doc);
        if (_myChart == undefined) {
            _myChart = echarts.init(doc); // 基于准备好的dom，初始化echarts实例
        }
        _myChart.clear(); // 清除画布
        doc.setAttribute('_echarts_instance_', '')
    },

    getDistrictUserCountInfos(keywords, adcode,level,userNumberFunc) {
        let param = {
          unitUuid:session.getLoginInfo().unitUuid,
          role:session.getLoginInfo().accountType,
          adcode:adcode,
          keywords:keywords,
          level:level
        }
        getDistrictUserCountInfos(param).then(res => {
          let mapData = new Map()
          if (res.data.code == 200) {
            var userCountData = res.data.dataInfo.adcodeList;

            if(userNumberFunc) {
                userNumberFunc(res.data.dataInfo.count)
            }
            
            userCountData.forEach(item => {
                let key = item.adcode;
                mapData.set(key,item.number)
            })
          }
          this.getDistrict(keywords, adcode,mapData);
        })
      },

      getDistrictDeviceCountInfos(keywords, adcode,level,userNumberFunc) {
        let param = {
          unitUuid:session.getLoginInfo().unitUuid,
          role:session.getLoginInfo().accountType,
          adcode:adcode,
          keywords:keywords,
          level:level
        }
        getDistrictDeviceCountInfos(param).then(res => {
          let mapData = new Map()
          if (res.data.code == 200) {
            var userCountData = res.data.dataInfo.adcodeList;
            if(userNumberFunc) {
                userNumberFunc(res.data.dataInfo.count)
            }
            userCountData.forEach(item => {
                let key = item.adcode;
                mapData.set(key,item.number)
            })
          }
          this.getDistrict(keywords, adcode,mapData);
        })
      },

    /**
 * 获取行政区数据
 */
    getDistrict(keywords, adcode,mapData) {
        let params = {
            keywords: keywords,
        };
        getDistrict(params).then((res) => {
            const data = res.data.districts;
            data.forEach((item) => {
                if(adcode == item.adcode) {
                    this.getDataEx(item.districts, adcode,mapData)
                }
                
            });
        });
    },
    getDataEx(districts, adcode,userMapData) {
        if (districts) {
            _mapData = [];
            // var districtValue = [3, 10000, 50, 300, 450,30,700];
            var sortArray = []
            for (var i = 0, l = districts.length; i < l; i++) {
                var name = districts[i].name;
                var cityCode = districts[i].adcode;
                var value = userMapData.get(cityCode)//districtValue[this.getRandomNum(0, 6)];

                _mapData.push({
                    name: name,
                    value: value,//Math.random() * 100
                    cityCode: cityCode
                });
                sortArray.push(value)
            }
            _maxValue = eval("Math.max(" + sortArray.toString() + ")");
            _minValue = eval("Math.min(" + sortArray.toString() + ")");
            if(_maxValue == _minValue) {
                _maxValue = _minValue + 5000
            }
            this.loadMapData(adcode,userMapData);
        }
    },
    getRandomNum(Min, Max) {
        var Range = Max - Min;
        var Rand = Math.random();
        return (Min + Math.round(Rand * Range));
    },
    loadMapData(adcode,userMapData) {
        // if (_mapJson != null) {
        //     _myChart.setOption(_option);
        // } else {
            AMapUI.loadUI(['geo/DistrictExplorer'], (DistrictExplorer) => {
                //创建一个实例
                var districtExplorer = window.districtExplorer = new DistrictExplorer({
                    eventSupport: true, //打开事件支持
                    map: _map
                });
                districtExplorer.loadAreaNode(adcode, (error, areaNode) => {
                    if (error) {
                        console.error(error);
                        return;
                    }
                    _mapJson = {};
                    _mapJson.features = areaNode.getSubFeatures();
                    // _mapJson.features = areaNode.getSubFeatureByAdcode(adcode);
                    if (0 == _mapJson.features.length) {
                        _mapJson = {};
                        _mapJson.features = [].concat(areaNode.getParentFeature(adcode));

                        _mapData.splice(0, _mapData.length);
                        _mapData.push({
                            name: _cityName,
                            value: userMapData.get(adcode),
                        });
                    }
                    this.loadMap(_cityName, _mapJson);
                });
            });
        // }
    },
    // var colorValue = ['#0a5abd','#2444ba','#1432a3','#1a3595','#172d7b'];

    loadMap(mapName, mapData) {
        if (mapData) {
            _echarts.registerMap(mapName, mapData);
            _option = {
                // backgroundColor: '#0e1d4f',
                // title: {
                //     text: _cityName + '用户分布图',
                //     textStyle: {
                //         // color: '#fff',
                //         fontSize:20,
                //     },
                //     left:20,
                //     top:5
                //   },
                visualMap: [{
                    min: _minValue,
                    max: _maxValue,
                    text: ['高', '低'],
                    realtime: false,
                    calculable: true,
                    left:20,
                    inRange: {
                        // color: ['lightblue', 'blue', '#733fcc']
                        color: ['#e6e9f9', '#c6cef8', '#9bacfd', '#5a75f8', '#3152f4', '#1839dc', '#0e259a', '#0b497c']
                    },
                    textStyle: {
                        // color: '#fff'
                    }
                }],
                tooltip: {
                    trigger: 'item',
                    // formatter: '{b}<br/>{c}'
                    formatter: function (params) { 
                        if(params.value){ //params.seriesName + '<br/>' + 
                            return params.name + ' : ' + params.value; 
                        }else{ 
                            return params.name + ' : ' + '0'; } 
                        } 
                },
                geo: {
                    show: true,
                    map: mapName,
                    label: {
                        normal: {
                            // show: true,
                            // textStyle: {
                            //     color: '#fff'
                            // }
                        },
                        emphasis: {
                            show: true,
                            textStyle: {
                                color: '#fff'
                            }
                        }
                    },
                    roam: false,//是否开启鼠标缩放和平移漫游
                    zoom: 1.2, //当前视角的缩放比例。
                    itemStyle: {
                        // normal: {
                        //     areaColor: '#1432a3',
                        //     borderColor: '#afd3f8',
                        //     borderWidth: 1.1,
                        // },
                        emphasis: {
                            areaColor: '#1432a3',
                            borderColor: '#afd3f8',
                            label: {
                                show: true,
                                textStyle: {
                                    // color: '#fff'
                                }
                            }
                        }
                    },
                },
                series: [
                    {
                        type: 'map',
                        // roam: false,
                        name: mapName,
                        geoIndex: 0,
                        data: _mapData,//设备每个区的数量
                    }]
            };
            _myChart.setOption(_option);
        }
    },

};