/**
 * 主设备
 */
 import * as echarts from 'echarts'
 import ageSexJs from "./age-sex";
 import userMapJs from "./user-map";
 import deviceTypeJs from "./device-type";
 import warningTypeJs from "./warning-type";
 import session from "../../../../store/store";
 import MapArea from '../MapArea.vue'
import zhCn from'element-plus/lib/locale/lang/zh-cn'
 import {
  getUserCount,
  getDeviceCount,
  getWarningDataCount,
  getHealthDataCount,
  getAgeSexStatisInfo,
  getDeviceStateDataCount,
  getWarningStateDataCount,
  getDeviceTypeReportInfos,
  getWarningTypeReportInfos,
  getDistrictArea
} from '../../../../api/api'
export default {
  components: {
    MapArea
  },
    data() {
        return {
           zhCn,
           mapAreaShow:false,
           role:0,
           user:{
            total:0,
            newNum:0
           },
           device:{
            total:0,
            newNum:0
           },
           warning:{
            total:0,
            newNum:0
           },
           health:{
            total:0,
            newNum:0
           },
           deviceState:{
            onLine:0,
            offLine:0
           },
           warningState:{
            processed:0,
            notProcessed:0
           },
           dateType:'2',
           monthPicker: '',//月选择器绑定值
           yearPicker: "", //年选择器绑定值
           mapType: '1',//用户状态
           mapOption:[
            {value: "1",label: '用户分布图'},
            {value: "2",label: '设备分布图'}
          ],
          cityName:'中国',
          level:0,
          adcode:'100000',
          userNumber:0,
        };
    },
    mounted() {
      this.init();
    },
    methods: {
      init() {
        this.role = session.getLoginInfo().accountType;
        this.getUserCount();
        this.getDeviceCount();
        this.getWarningDataCount();
        this.getHealthDataCount();
        this.getAgeSexStatisInfo();
        this.getDeviceStateDataCount();
        this.getWarningStateDataCount();
        this.getDeviceTypeReportInfos();
        this.getWarningTypeReportInfos();
        this.getDistrictArea();
      },

      getUserCount() {
        let param = {
          unitUuid:session.getLoginInfo().unitUuid,
          role:this.role,
        }
        getUserCount(param).then(res => {
          if (res.data.code == 200) {
            this.user = res.data.dataInfo;
          }
        })
      },
      getDeviceCount() {
        let param = {
          unitUuid:session.getLoginInfo().unitUuid,
          role:this.role,
        }
        getDeviceCount(param).then(res => {
          if (res.data.code == 200) {
            this.device = res.data.dataInfo;
          }
        })
      },
      getWarningDataCount() {
        let param = {
          unitUuid:session.getLoginInfo().unitUuid,
          role:this.role,
        }
        getWarningDataCount(param).then(res => {
          if (res.data.code == 200) {
            this.warning = res.data.dataInfo;
          }
        })
      },
      getHealthDataCount() {
        let param = {
          unitUuid:session.getLoginInfo().unitUuid,
          role:this.role,
        }
        getHealthDataCount(param).then(res => {
          if (res.data.code == 200) {
            this.health = res.data.dataInfo;
          }
        })
      },
      getDeviceStateDataCount() {
        let param = {
          unitUuid:session.getLoginInfo().unitUuid,
          role:this.role,
        }
        getDeviceStateDataCount(param).then(res => {
          if (res.data.code == 200) {
            this.deviceState = res.data.dataInfo;
          }
        })
      },
      getWarningStateDataCount() {
        let param = {
          unitUuid:session.getLoginInfo().unitUuid,
          role:this.role,
        }
        getWarningStateDataCount(param).then(res => {
          if (res.data.code == 200) {
            this.warningState = res.data.dataInfo;
          }
        })
      },
      getAgeSexStatisInfo() {
        let param = {
          unitUuid:session.getLoginInfo().unitUuid,
          role:this.role,
        }
        getAgeSexStatisInfo(param).then(res => {
          if (res.data.code == 200) {
            var ageSexData = res.data.dataInfo;
            ageSexJs.setAgeSexChart(echarts,ageSexData.ageList,ageSexData.maleList,ageSexData.femaleList);
          }else {
            var ageList = [0,0,0,0,0];
            ageSexJs.setAgeSexChart(echarts,ageList,ageList,ageList);
          }
        })
      },
      getDeviceTypeReportInfos() {
        let param = {
          unitUuid:session.getLoginInfo().unitUuid,
          role:this.role,
        }
        getDeviceTypeReportInfos(param).then(res => {
          var deviceTypeList = []
          if (res.data.code == 200) {
            var deviceType = res.data.dataInfo;
            
            deviceType.forEach(element => {
              deviceTypeList.push({'value':element.number,'name':element.typeDescribe})
            });
            deviceTypeJs.setDeviceTypeChart(echarts,deviceTypeList);
          }else {
            deviceTypeList.push({'value':0,'name':'其它'})
            deviceTypeJs.setDeviceTypeChart(echarts,deviceTypeList);
          }
        })
      },
      getWarningTypeReportInfos() {
        let param = {
          unitUuid:session.getLoginInfo().unitUuid,
          role:this.role,
        }
        getWarningTypeReportInfos(param).then(res => {
          var warningTypeList = []
          if (res.data.code == 200) {
            var warningType = res.data.dataInfo;
            
            warningType.forEach(element => {
              warningTypeList.push({'value':element.number,'name':element.typeDescribe})
            });
            warningTypeJs.setWarningTypeChart(echarts,warningTypeList);
          }else {
            warningTypeList.push({'value':0,'name':'其它'})
            warningTypeJs.setWarningTypeChart(echarts,warningTypeList);
          }
        })
      },
      getDistrictArea() {
        let param = {
          unitUuid:session.getLoginInfo().unitUuid,
          role:this.role,
        }
        getDistrictArea(param).then(res => {
          if (res.data.code == 200) {
            var areaData = res.data.dataInfo;
//           "name": "广东省#深圳市#南山区",
//           "adcode": "420000",
//           "level": 3,

            this.getAreaData(areaData);
          }
        })
      },
      setMapArea(){
        this.mapAreaShow = true
      },

      getAreaData(areaData) {
        var city = null;
        this.level = areaData.level
        this.adcode = areaData.adcode
        if(0 == areaData.level) {
          this.cityName = "中国"
        }else if(1 == areaData.level) {
          city = areaData.name.split('#')
          this.cityName = city[0];
        }else if(2 == areaData.level) {
          city = areaData.name.split('#')
          this.cityName = city[1];
        }else if(3 == areaData.level) {
          city = areaData.name.split('#')
          this.cityName = city[2];
        }
        userMapJs.initMap(echarts,this.cityName,this.adcode,this.level,this.mapType,(value =>{
          this.userNumber = value
        }));

      },
      update(mapData) {
        this.getAreaData(mapData);
      },
   
      changeMapType(event) {
        this.mapType = event;
        userMapJs.initMap(echarts,this.cityName,this.adcode,this.level,this.mapType,(value =>{
          this.userNumber = value
        }));
      },
 
  
  }
};